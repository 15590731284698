<template>
  <div
    class="tournament-page"
    :style="{
      'background-image': `url(${
        tournament && tournament.logo_img ? tournament.logo_img : ''
      })`,
    }"
  >
    <div class="tournament" v-if="!loadingFlag && tournament">
      <div class="tournament__content">
        <div class="tournament__title">
          <span>
            {{ tournament.name }}
          </span>
          <div class="font-weight-bold timer-block item-timer">
            <span>{{ $t("timeLeftBonus") }}</span>
            <Countdown :expireInSeconds="expireInSeconds" />
          </div>
        </div>
        <v-btn
          @click="toShowTournamentProviders"
          class="all-buttons-fw all-buttons-fw_green tournament__button"
        >
          {{ $t("play") }}
        </v-btn>
        <div class="tournament__description">
          {{ tournament.description }}
        </div>
        <div class="tournament__tables">
          <ul class="tournament__details">
            <li v-for="detail in tournamentDetails" :key="detail.type">
              <h2 v-if="detail.type === 'tournamentInfo'">
                {{ $t(detail.type) }}
              </h2>
              <span v-else>
                {{ $t(detail.type) }}
              </span>
              <strong v-if="detail.info">
                {{ detail.info }}
              </strong>
            </li>
          </ul>
          <div class="tournament__leaderboard">
            <ul class="leaderboard-tournament">
              <li class="leaderboard-tournament__row text-left">
                <h2>{{ $t("leaderboard") }}</h2>
              </li>
              <li class="leaderboard-tournament__row is-header">
                <div class="leaderboard-tournament__column text-left">
                  {{ $t("place") }}
                </div>
                <div class="leaderboard-tournament__column">
                  {{ $t("player") }}
                </div>
                <div class="leaderboard-tournament__column is-points">
                  {{ $t("points") }}
                </div>
                <div class="leaderboard-tournament__column is-prize text-right">
                  {{ $t("prize") }}
                </div>
              </li>
              <div
                class="leaderboard-tournament__players custom-scroll"
                ref="playersContainer"
              >
                <v-skeleton-loader
                  tile
                  width="100%"
                  height="130px"
                  type="image"
                  v-if="loadingFlag"
                ></v-skeleton-loader>
                <template v-else-if="leaderboardItems.length">
                  <li
                    class="leaderboard-tournament__row"
                    :class="isOurPlace(leader) ? '_our_place' : ''"
                    v-for="leader in leaderboardItems"
                    :key="leader.id"
                    ref="leaderItems"
                  >
                    <b class="leaderboard-tournament__column text-left">
                      {{ leader.leaderboard_position }}.
                    </b>
                    <div class="leaderboard-tournament__column">
                      {{
                        isOurPlace(leader)
                          ? `${user.username}`
                          : leader.username
                      }}
                    </div>
                    <div class="leaderboard-tournament__column is-points">
                      {{ leader.current_score }}
                    </div>
                    <div
                      class="leaderboard-tournament__column is-prize text-right"
                    >
                      {{ leader.final_prize_amount }} {{ user.currency }}
                    </div>
                  </li>
                </template>
                <template v-else>
                  <b class="mt-4 d-block">
                    {{ $t("noPlayerLeaderboard") }}
                  </b>
                </template>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <v-skeleton-loader
      height="100%"
      width="100%"
      class="skeleton-full"
      type="image"
      v-else-if="loadingFlag"
    ></v-skeleton-loader>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Countdown from "@/components/Countdown/Timer";
export default {
  name: "TournamentView",
  components: {
    Countdown,
  },
  data() {
    return {
      loadingFlag: false,
    };
  },
  async mounted() {
    const url = window.location.href;
    const urlArray = url.split("/");
    const id = urlArray[urlArray.length - 1];
    this.loadingFlag = true;
    await this.getTournament(id);
    await this.toLoadLeaderboard(id);
    this.loadingFlag = false;
    const container = this.$refs.playersContainer;
    if (container) {
      container.addEventListener("scroll", this.setStickyForOurPlace);
    }
  },
  computed: {
    ...mapGetters({
      tournament: "tournaments/getTournament",
      user: "getUserInfo",
    }),
    tournamentDetails() {
      return [
        {
          type: "tournamentInfo",
          info: null,
        },
        {
          type: "tournamentBonusPool",
          info: `${this.tournament.prize_pool_amount} ${this.user.currency}`,
        },
        {
          type: "tournamentBonusPaid",
          info: `${
            this.tournament.prize_distribution &&
            this.tournament.prize_distribution.length
              ? this.tournament.prize_distribution[
                  this.tournament.prize_distribution.length - 1
                ].leaderboard_position
              : 0
          }
        (${this.$t("grandPrize")} - ${
            this.tournament.prize_distribution &&
            this.tournament.prize_distribution.length
              ? this.tournament.prize_distribution[0].prize_amount
              : 0
          } ${this.user.currency})
          `,
        },
        {
          type: "tournamentBonusFrequency",
          info: this.$t(this.tournament.repeats),
        },
      ];
    },
    leaderboardItems() {
      return this.tournament && this.tournament.leaderboard
        ? this.tournament.leaderboard.items
        : [];
    },
    expireInSeconds() {
      if (!this.tournament.end_time) return 0;
      const now = new Date();
      const endTime = new Date(this.tournament.end_time);
      const differenceInSeconds = Math.max(
        Math.floor((endTime - now) / 1000),
        0
      );
      return differenceInSeconds;
    },
  },
  methods: {
    isOurPlace(leader) {
      return (
        this.tournament.leaderboard_position === leader.leaderboard_position
      );
    },
    setStickyForOurPlace() {
      const ourPlaceElement = this.$refs.leaderItems.find((el) =>
        el.classList.contains("_our_place")
      );

      if (ourPlaceElement) {
        const container = this.$refs.playersContainer;
        const { top, bottom } = container.getBoundingClientRect();
        const elementTop = ourPlaceElement.getBoundingClientRect().top;
        if (elementTop < top) {
          ourPlaceElement.style.position = "absolute";
          ourPlaceElement.style.top = "0";
        } else if (elementTop > bottom) {
          ourPlaceElement.style.position = "absolute";
          ourPlaceElement.style.bottom = "0";
        } else {
          ourPlaceElement.style.position = "sticky";
          ourPlaceElement.style.bottom = "0";
        }
      }
    },
    toShowTournamentProviders() {
      this.$router.push(
        `/tournament/${this.tournament.id}/section/provider/all`
      );
    },
    async getTournament(id) {
      try {
        await this.$store.dispatch("tournaments/awaitGetTournament", id);
      } catch (error) {
        const notification = {
          type: "error",
          message: this.$t("justError"),
        };
        this.$store.dispatch("alerts/add", notification);
      }
    },
    async toLoadLeaderboard(id) {
      this.loadingFlag = true;
      await this.$store.dispatch("tournaments/awaitGetLeaderboard", id);
      this.loadingFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-page {
  margin-top: 56px;
  background-color: var(--dark-flamingo);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  .is-email-confirmation-bar-visible & {
    margin-top: 90px;
    @media (max-width: 992px) {
      margin-top: 125px;
    }
  }
  &::before {
    content: "";
    opacity: 0.9;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--dark-d-flamingo);
  }
}
.tournament {
  background: transparent;
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0 auto;
  max-width: 900px;
  overflow: hidden !important;
  padding: 20px 15px 40px;
  .is-desktop & {
    height: 100%;
    padding: 5px 20px 20px;
  }
  ul {
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 6px;
    padding: 0;
    position: relative;
  }
  li {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
    line-height: 1.5;
    min-height: 40px;
    padding: 10px 15px;
  }
  &__description {
    background: hsla(0, 0%, 100%, 0.1);
    border-radius: 6px;
    padding: 0;
    position: relative;
    display: flex;
    width: 100%;
    line-height: 1.5;
    padding: 6px 15px;
  }
  &__header {
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: var(--white) !important;
    .is-desktop & {
      height: 100%;
      background-position: 50%;
      background-size: cover;
    }
  }
  &__title {
    word-break: break-word;
    text-transform: uppercase;
    transition: all 0.3s;
    font-weight: 900;
    color: var(--white) !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-height: 100px;
    span {
      font-size: 47px;
    }
    order: -2;
    position: relative;
  }
  &__tables {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
    .is-desktop & {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__details {
    overflow: hidden;
    &:first-child {
      li {
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        line-height: 25px;
        margin: 0;
        text-transform: uppercase;
      }
    }
    h2 {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
      margin: 0;
      text-transform: uppercase;
    }
    li {
      grid-template-columns: auto 1fr;
      grid-gap: 0;
      align-items: center;
      display: grid;
      grid-template-columns: auto auto;
      line-height: 1.5;
      min-height: 40px;
      padding: 10px 15px;
      &:nth-child(2n) {
        background: hsla(0, 0%, 100%, 0.05);
      }
      span {
        text-align: left;
        padding-right: 15px;
      }
      strong {
        text-align: right;
        font-weight: 600;
      }
    }
  }

  &__leaderboard {
  }
  &__button {
    width: 200px;
  }
}
.header-tournament {
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: space-between;
  margin: 0 -10px 5px;
  grid-column: 1 / -1;
  position: relative;
  z-index: 1;
  &__label,
  &__close {
    height: 44px;
    justify-content: center;
    padding: 10px;
    position: relative;
    width: 44px;
    display: flex;
    align-items: center;
  }

  &__title {
    color: #fff;
    display: block;
    line-height: 1.5;
    max-width: calc(100% - 68px);
    word-wrap: normal;
    cursor: default;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 5;
  }
  &__close {
    cursor: pointer;
  }
}
.leaderboard-tournament {
  max-height: 100%;
  overflow-y: auto;
  position: relative;

  .is-header {
    padding: 10px 35px 10px 15px;
  }
  .is-points {
  }
  .is-prize {
  }
  &__players {
    max-height: 250px;
    overflow-y: auto;
    position: relative;
  }
  &__row {
    padding: 10px 15px;
    grid-template-columns: 0.7fr repeat(3, 1fr) !important;
    &:nth-child(2n) {
      background: hsla(0, 0%, 100%, 0.05);
    }
    &._our_place {
      border: 1px solid var(--red);
      position: sticky;
      background-color: var(--main-flamingo);
      z-index: 5;
      bottom: 0;
      width: 100%;
    }
  }

  &__column {
    &:last-child {
      font-weight: 600;
    }
  }
}
.timer-block {
  min-height: 48px;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--red);
  border-radius: 6px;
  background-color: rgb(78 0 72 / 85%);
  div {
    font-size: 12px;
  }
  span {
    font-size: 12px;
    color: var(--white-design);
  }
}
</style>
